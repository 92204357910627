import { LoadingEntityInfo, PrefetchedEntityInfo } from './EntityInfo'
import { InfoFetcherProps } from './types'
import { useTeam, getInitialism, useProfile, getLevelName } from '@front/model'
import { Text } from '@mantine/core'
import { Link } from '@remix-run/react'
import type { TeamLevel, TinyTeam, WithTeamId } from '@terros/common'
import type { ReactElement, ReactNode } from 'react'

type TeamInfoProps = InfoFetcherProps & WithTeamId

export const TeamInfo = ({ teamId, linked, ...avatarProps }: TeamInfoProps): ReactNode => {
  const { data, isLoading, error } = useTeam(teamId)

  if (error) return <Text className='text-red-400'>{error}</Text>
  if (isLoading || !data) return <LoadingEntityInfo {...avatarProps} />

  return <PrefetchedTeamInfo linked={linked} {...avatarProps} team={data.team} />
}

type TeamInfoPrefetchedInput = InfoFetcherProps & {
  team: TinyTeam & {
    level?: TeamLevel
  }

  companyName?: string
}

export const PrefetchedTeamInfo = (props: TeamInfoPrefetchedInput): ReactElement => {
  const { company } = useProfile()

  const { linked = true, team, ...rest } = props

  const { teamId, name, ...teamProps } = team

  const entityInfo = {
    ...rest,
    ...teamProps,
    name: team.level ? `${getLevelName(company, team.level)}: ${name}` : name,
    initials: getInitialism(name),
  }

  if (linked) {
    return (
      <Link to={`/team/${teamId}`}>
        <PrefetchedEntityInfo {...entityInfo} />
      </Link>
    )
  }
  return <PrefetchedEntityInfo {...entityInfo} />
}
